import GmxGlobal from "@/js/global";
import DateUtil from "./DateUtil";

const CONVERT_COLTYPE_DATE = ["creDat", "targetEtd","contDat", "updDat", "projStd", "taxDat", "projEnd", "birthDay", "contStd", "contEnd", "eStdDat", "eEndDat", "inspecDate"];
const CONVERT_COLTYPE_TIME = [ "useTime"];
const CONVERT_COLTYPE_STATE = ["ordersState", "selesStateNm", "projStateNm", "ordersStateNm", "outsStateNm"];
const CONVERT_COLTYPE_PAY = ["exAmount", "realAmount", "payAmount", "pubAmount", "solPrice", "exOutAmount", "amountTotal", "taxTotal", "totalAmount"];
const CONVERT_COLTYPE_TAG_ICON = ["clientNm"];
const CONVERT_COLTYPE_ICON = ["fileTyp"];
const CONVERT_COLTYPE_LVL = ['accLvl', 'clientLvl'];

const CONVERT_COLTYPE_KEY = ["userId"];
const CONVERT_COLTYPE_SELECTBOX = ["deptCd", "authGrpSeq", "departNm", "authStatNm"];
const CONVERT_COLTYPE_SELECTBOX_ORG = ["orgMgrSeq", "deptCd"];

const CONVERT_COLTYPE_PW = ["userPwd"];
const CONVERT_COLTYPE_SERVICE_NAME= ["tgtNm"];

export default {
    /**
     * (table list 전용)각 컬럼의 타입 별로.. table에 컨텐츠 만들기 위해 타입 지정..!
     * - 몰러.
     *
     * @param {} refData
     * @returns
     */
    convertRefToColumns: (refData, exclude) => {
        return refData.filter(item => {
            if (CONVERT_COLTYPE_PAY.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_PAY;
            }

            if (CONVERT_COLTYPE_DATE.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_DATE;
            }

            if (CONVERT_COLTYPE_TIME.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_TIME;
            }

            if (CONVERT_COLTYPE_STATE.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_STATE;
            }

            if (CONVERT_COLTYPE_KEY.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_KEY;
            }

            if (CONVERT_COLTYPE_TAG_ICON.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_TAG_ICON;
            }

            if (CONVERT_COLTYPE_ICON.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_ICON;
            }

            if (CONVERT_COLTYPE_SERVICE_NAME.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_SERVICE_NAME;
            }

            if (exclude && exclude.includes(item.id)) {
                return false;
            }

            return true;
        });
    },

    getRefType: (id) => {
        let type = '';

        if (CONVERT_COLTYPE_PAY.includes(id)) {
            type = GmxGlobal.COL_COLTYPE_CD_PAY;
        }

        if (CONVERT_COLTYPE_DATE.includes(id)) {
            type = GmxGlobal.COL_COLTYPE_CD_DATE;
        }

        if (CONVERT_COLTYPE_TIME.includes(id)) {
            type = GmxGlobal.COL_COLTYPE_CD_TIME;
        }

        if (CONVERT_COLTYPE_STATE.includes(id)) {
            type = GmxGlobal.COL_COLTYPE_CD_STATE;
        }

        if (CONVERT_COLTYPE_KEY.includes(id)) {
            type = GmxGlobal.COL_COLTYPE_CD_KEY;
        }

        if (CONVERT_COLTYPE_TAG_ICON.includes(id)) {
            type = GmxGlobal.COL_COLTYPE_CD_TAG_ICON;
        }

        if (CONVERT_COLTYPE_ICON.includes(id)) {
            type = GmxGlobal.COL_COLTYPE_CD_ICON;
        }

        if (CONVERT_COLTYPE_SERVICE_NAME.includes(id)) {
            type = GmxGlobal.COL_COLTYPE_CD_SERVICE_NAME;
        }

        return type;

    },

    /**
     * (Form 전용) 각 컬럼의 타입 별로.. table에 컨텐츠 만들기 위해 타입 지정..!
     * - 몰러.
     *
     * @param {} refData
     * @returns
     */
    convertFormRefToColumns: (refData, exclude) => {
        return refData.filter(item => {

            if ( item.id && item.id.indexOf('.') != -1 ) item.id = item.id.split('.')[1];

            if (CONVERT_COLTYPE_PAY.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_PAY;
            }

            if (CONVERT_COLTYPE_DATE.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_DATE;
            }

            if (CONVERT_COLTYPE_STATE.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_STATE;
            }
            if (CONVERT_COLTYPE_LVL.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_LVL;
            }

            if (CONVERT_COLTYPE_KEY.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_KEY;
            }

            if (CONVERT_COLTYPE_SELECTBOX.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_SELECT;
            }
            if (CONVERT_COLTYPE_SELECTBOX_ORG.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_SELECT_ORGZ;
            }
            if (CONVERT_COLTYPE_PW.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_PW;
            }

            if (CONVERT_COLTYPE_ICON.includes(item.id)) {
                item.type = GmxGlobal.COL_COLTYPE_CD_ICON;
            }

            if (exclude && exclude.includes(item.id)) {
                return false;
            }

            return true;
        });
    },

    /**
     * 입력받은 arr중에서 tgtId에 해당하는 tgtNm을 원하는 값으로 변경한다.
     * @param {Array} arr
     * @param  {Array} tgtId
     * @param  {Array} tgtNm
     */
    convertColumnsName: (arr, tgtId, tgtNm) => {

        // arr의 각 요소에 대해 반복
        arr.forEach((obj, idx) => {
            // 현재 객체의 id 값이 tgtId 배열에 속해 있다면
            if (tgtId.includes(obj.id)) {
                // idx 값으로 tgtNm 배열에서 적절한 이름을 찾아서 name 값을 변경
                const tgtIdx = tgtId.indexOf(obj.id);
                if (tgtIdx !== -1) {
                    arr[idx] = { ...obj, name: tgtNm[tgtIdx] };
                }
            }

        return arr;

        });
    },

    /**
     * 서버로 부터 받은 데이터를 ref로 치환
     *
     * @param {d} refData
     * @param {*} exclude
     * @returns
     */
    convertColToRef: (refData, exclude) => {
        const convertData = {};
       
        for (const key in refData) {
            let data = refData[key];
            if (exclude && exclude.includes(key)) {
                continue;
            }

            if (CONVERT_COLTYPE_PAY.includes(key)) {
                if(data != null) {
                    data = Number(data).toLocaleString();
                }
            }
            if (CONVERT_COLTYPE_DATE.includes(key)) {
                data = DateUtil.convertDateData(data);
            }
            if (CONVERT_COLTYPE_TIME.includes(key)) {
                data = DateUtil.convertDateData(data, true);
            }

            if (CONVERT_COLTYPE_ICON.includes(key) && data) {
                
                //에라 모르겠따..더워
                const convertData = (_data) =>{
                    let str = '';
                    
                    _data.split(',').forEach(item=> {
                        str +=  ',' + item.split(';;')[0];
                    });

                    return str.substring(1);
                }
                data= data.indexOf(',') == -1 ? data.split(';;')[0] : convertData(data);
            }

            //join...로직 덕분에..후
            convertData[key.indexOf('.') != -1 ? key.split('.')[1] : key] = data;
        }

        return convertData;
    },

    /**
     * ref 데이터를 서버로 보내기 위해 Object 형식으로 치환
     *
     * @param {*} refData
     * @param {*} exclude
     * @returns
     */
    convertRefToParam: (refData, exclude) => {
        const convertData = {};

        for (const key in refData.value) {
            let data = refData.value[key];

            if (exclude && exclude.includes(key)) {
                continue;
            }
            
            if (data && CONVERT_COLTYPE_PAY.includes(key)) {
                
                //임시 조치
                data = data.replaceAll(",", "");
            }

            if (data && CONVERT_COLTYPE_DATE.includes(key)) {
                data = data.replaceAll("-", "");
            }

            convertData[key] = data;
        }

        return convertData;
    },

    /**
     * 영업상태 코드값을 코드명으로 치환
     *
     * @param {*} refData
     * @param {*} exclude
     * @returns
     */
    convertSaleCodeToName: (code, type) => {
        if(type === "SALE"){
            switch (code) {
                case GmxGlobal.SALE_STATE_0:
                    return "기회";
                case GmxGlobal.SALE_STATE_1:
                    return "고려";
                case GmxGlobal.SALE_STATE_2:
                    return "적극";
                case GmxGlobal.SALE_STATE_3:
                    return "확실";
                case GmxGlobal.SALE_STATE_4:
                    return "수주";
                case GmxGlobal.SALE_STATE_5:
                    return "실패";
                
                default:
                    return "기회"; 
            }
        }else if(type === "ORDERS" || type === "OUTS"){
            switch (code) {
                case GmxGlobal.CONT_STATE_0:
                    return "계획";
                case GmxGlobal.CONT_STATE_1:
                    return "계약완료";
                case GmxGlobal.CONT_STATE_2:
                    return "진행";
                case GmxGlobal.CONT_STATE_3:
                    return "완료";
                case GmxGlobal.CONT_STATE_4:
                    return "검사검수";
                case GmxGlobal.CONT_STATE_5:
                    return "계산서 발행";
    
                default:
                    return "계획";   
            }
        }
        
    },

    /**
     * 체크된 값만을 필터하여 반환하는 용도
     *
     * 1. 체크값 필터
     * 2. proxyObject를 object로 치환
     *
     * 이것도 일단은 물어보자
     *
     * @param {} dataRef
     */
    convertRefToSelectedRef: (dataRef) => {
        const selectedDataRef = dataRef.value.filter(item => {
            return item.selected === true;
        });

        const convertData = selectedDataRef.map(proxyObj => {
            return { ...proxyObj };
        });

        return convertData;
    },

    /**
     * 중복값을 제거하여 Array형태로 반환
     * 
     * @param {*} convertData
     * @param {*} symbol
     * @returns
     */
    convertDataToUniqueArray: (convertData, includeKeys) => {
        
        //convertData는 Array 안의 Object 형태로 구성....
        const uniqueArray = [];
        
        //여기서 symbol 형태로 param에 담아준다.
        for ( let i=0; i<convertData.length; i++ ){
            const objData = convertData[i];
            let obj = null;
            
            for(const key in objData) {
                if (includeKeys.includes(key)) {
                    if( !obj ) obj = {};

                    obj[key] = objData[key];
                }
            }

            if( obj ) {
                //기존 객체랑 체크.obj.string
                let isUnique = false;
                uniqueArray.forEach((item)=>{
                    
                    if ( JSON.stringify(obj) === JSON.stringify(item) ) {
                        isUnique = true;
                    } 
                });

                if ( !isUnique ) uniqueArray.push(obj);
            }

        }

        return uniqueArray;
    },

    /**
     * api로 전달 받은 값이 오류인지 정상 요청인지 판별한다.
     * 
     * @param {*} status res.statues ( 200 || OK 등등)
     * @returns 
     */
    responseChk: (status) => {
        if ( GmxGlobal.API_OK === status || GmxGlobal.API_CODE_OK === status ) {
            return true;
        } else {
            return false;
        }
    },

    /**
     * 객체 배열을 정렬한다.
     * 
     * @param {*} array 객체로된 배열
     * @param {*} key 정렬에서 사용할 객체 key값
     * @param {*} keys keys순으로 정렬
     * @returns 
     */
    sortArrayByKeys: (array, key, keys) => {
        return array.sort((a, b) => {
        //후.. 공통 join 떄문에 어쩔 수 없다..
        //정렬...후
        let a1 = a[key];
        let b1 = b[key];
        if ( a1 && a1.indexOf('.') != -1 ) a1 = a[key].split('.')[1];
        if ( b1 && b1.indexOf('.') != -1 ) b1 = b[key].split('.')[1];
        
        let indexA = keys.indexOf(a1);
        let indexB = keys.indexOf(b1);

        if (indexA === undefined || indexB === undefined) {
            // 포함되지 않은 컬럼은 맨 뒤로 보냅니다.
            return indexA === undefined ? 1 : -1;
            }
    
        if (indexA < indexB) {
            return -1;
        } else if (indexA > indexB) {
            return 1;
        }
    
        return 0;
        });
    },
  
    /**
     * 포멧팅 형식으로 문자열을 리턴한다.
     *
     *  - formatString('{0}건을 표출합니다.', 123);
     *    => 13건을 표출합니다.
     * @param {*} template
     * @param  {...any} params
     * @returns
     */
    formatString: (template, ...params) => {
        return template.replace(/{(\d+)}/g, (match, index) => {
            return params[index] !== undefined ? params[index] : match;
        });
    },

    /**
     * 텍스트를 복사한다.
     *
     * 1. https에만 먹히는 요즘 최신 javascript copy 방식
     * 2. http에서도 되는 이제는 deprecated javascript copy 방식
     *
     * @param {*} text
     * @returns
     */
    copyToClipBoard: async (text) => {
        let sucess = false;

        if (window.navigator.clipboard) {
            await window.navigator.clipboard
                .writeText(text)
                .then(() => {
                    sucess = true;
                })
                .catch(() => {
                    sucess = false;
                });
        } else {
            const textarea = document.createElement("textarea");
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();

            sucess = document.execCommand("Copy");

            document.body.removeChild(textarea);
        }

        return sucess;
    },

    
    /**
     * 배열 텍스트 또는 split 로 글자를 자른 경우 문자를 치환한다.
     *  - ㅇㅇ외 {length} 곳
     *  - ;;로 구분된다..
     *  - 의존이 너무 심해서 여기로 옴김.
     * @param {*} amount 
     * @returns 
     */
    convertArrayStrign: ( arrayStr ) => {
        if (!arrayStr ) return '';
        return arrayStr.indexOf(',') == -1 ? arrayStr.split(';;')[0] : arrayStr.split(',')[0].split(';;')[0] + ' 외 ' + (arrayStr.split(',').length -1) + ' 건';
    },

        
    /**
     * 배열 텍스트 또는 split 로 글자를 자른 경우 문자를 치환한다.
     *  - ㅇㅇ외 {length} 곳
     *  - ;;로 구분된다..
     *  - 의존이 너무 심해서 여기로 옴김.
     * @param {*} amount 
     * @returns 
     */
    getClientLevelIcon: ( arrayStr ) => {
 
        if (!arrayStr ) return '';
        const imgPath = `/img/icon/icon_{IMG}st.png`;
        const img = arrayStr.indexOf(',') == -1 ? arrayStr.split(';;')[1] : arrayStr.split(',')[0].split(';;')[1];

        if ( !img || img == '00' ) return '';
        return `${imgPath.replace("{IMG}", img)}`;
    },

    /**
     * 이력 메세지를 컨버팅 한다.
     * 
     */
    convertHistMsg: (histCd, userId, content) => {
        console.log(histCd, userId);
        return ''+content;
    },

    getProjCd : (orgMgrSeq, mntChk) => {
        let cd = 'PJA';

        switch(orgMgrSeq) {
            case 10001 : //AP
    
              cd = ( mntChk === "Y" ? "MTA" : "PJA" );
              break;
              case 10002 : //DP
              cd = ( mntChk === "Y" ? "MTB" : "PJB" );
              break;
              case 10003 : //연구소
              cd = ( mntChk === "Y" ? "RND" : "PJD" );
              break;
            case 10004 : //경영지원본부
              cd = ( mntChk === "Y" ? "PJE" : "PJE" );
              break;
            case 10005 : //지오멕스
                cd = ( mntChk === "Y" ? "PJE" : "PJE" );
                break;
            case 10006 : //해외사업부
                cd = ( mntChk === "Y" ? "PJC" : "PJC" );
              break;
    
          }

        return `${cd}-${DateUtil.getY().substring(2)}`;
    },

    /**
     * 리스트 날짜 범위 검색 용 
     * @param {*} _stdDate 
     * @returns 
     */
    getSearchDateRange : (_stdDate) => {

        if ( _stdDate.split ) {
            //날짜 검색
            const getDay = _stdDate.split(' - ');

            return {
                std : getDay[0],
                end : getDay[1]
            }
        } else {

            //날짜 검색
            const getDay = (_date) => {
                const sday = _date.getDate().toString().padStart(2, '0');
                const smonth = (_date.getMonth() + 1).toString().padStart(2, '0');
                const syear = _date.getFullYear();
                return `${syear}-${smonth}-${sday}`;
            }
        
            return {
                std : getDay(_stdDate[0]),
                end : getDay(_stdDate[1])
            }
        }
    
    },

    validKey : (svc, cd) => {

        let regex = /^(GDP|GAP)-\d{2}-\d{3}$/;
        switch(svc) {
            case GmxGlobal.SERVICE_CODE_PROJ :
                regex = /^(MTA|MTB|PJA|PJB|PJ|RND|PJD)-\d{2}-\d{3}$/;
                break;
            case GmxGlobal.SERVICE_CODE_SALE :
                regex = /^(GDP|GAP)-\d{2}-\d{3}$/;
                break;
            case GmxGlobal.SERVICE_CODE_OUTS :
                regex = /^\d{2}-\d{3}-\d{2}$/;
                break;
            case GmxGlobal.SERVICE_CODE_ORDERS :
                regex = /^(GM)-\d{2}-\d{3}$/;
                break;

        }
        return regex.test(cd);
        
    },
    
    /**
     * 확장자 명으로 파일 아이콘을 불러온다.
     * 
     * @param {*} ext 
     * @returns 
     */
    convertFileIcon : (ext) => {
        let type = 'etc';

        switch ( ext ) {
            case 'png' :
            case 'svg' :
            case 'jpg' :
            case 'jpeg' :
                type = 'png';
                break;
            case 'hwp' :
                type = 'hwp';
                break;
            case 'pdf' :
                type = 'pdf';
                break;
            case 'word' :
                type = 'word';
                break;
            case 'xls' :
                type = 'xls';
                break;
            case 'zip' :
                type = 'zip';
                break;
        }

        return `/img/icon/icon_${type}.svg`;
    },

    /**
     * 테이블명으로 한글 명칭을 가져온다.
     * 
     * @param {*} tbName 
     * @returns 
     */
    convertServiceNm : (tbName) => {
        if(tbName.includes('ct_sale')) return '영업';
        if(tbName.includes('ct_orders')) return '수주계약';
    },
};
